import React, { useCallback, useEffect } from "react"
import Portal from "../Portal/Portal"
import { useStaticQuery, graphql } from "gatsby"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import "./callbackForm.scss"
import logo from "../../images/logo.png"
function CallbackForm({ open, setOpen }) {
  const data = useStaticQuery(graphql`
    query formQuery1 {
      wp {
        ...GravityFormSettings
      }
      wpGfForm(databaseId: { eq: 1 }) {
        ...GravityFormFields
      }
    }
  `)
  const escFunction = useCallback(event => {
    if (event.key === "Escape") {
      setOpen(false)
    }
  }, [])
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [setOpen, escFunction])
  // if (open)
  //   return (
  //     <div className={`callback-form ${open && "open"}`}>
  //       <div
  //         className="close-button"
  //         onClick={() => {
  //           setOpen(false)
  //         }}
  //       >
  //         Q
  //       </div>
  //       <div
  //         className="overlay"
  //         onClick={() => {
  //           setOpen(false)
  //         }}
  //       ></div>
  //       <div className="container">
  //         <div className="form-wrapper">
  //           <div className="form-header">
  //             <div className="logo">
  //               <img src={logo} alt="Logo" />
  //             </div>
  //             <div className="form-title">
  //               <h3>Request a callback</h3>
  //               <p>
  //                 If you want to talk about your Employment Law or Health and
  //                 Safety requirements with one of our experts, please complete
  //                 the form below and we will call you back within one hour.
  //               </p>
  //             </div>
  //           </div>
  //           <GravityFormForm data={data} />
  //         </div>
  //       </div>
  //     </div>
  //   )
  return (
    open && (
      <Portal>
        <div className={`callback-form ${open && "open"}`}>
          <div
            className="close-button"
            onClick={() => {
              setOpen(false)
            }}
          >
            Q
          </div>
          <div
            className="overlay"
            onClick={() => {
              setOpen(false)
            }}
          ></div>
          <div className="container">
            <div className="form-wrapper">
              <div className="form-header">
                <div className="logo">
                  <img src={logo} alt="Logo" />
                </div>
                <div className="form-title">
                  <h3>Request a callback</h3>
                  <p>
                    If you want to talk about your Employment Law or Health and
                    Safety requirements with one of our experts, please complete
                    the form below and we will call you back within one hour.
                  </p>
                </div>
              </div>
              <GravityFormForm data={data} />
            </div>
          </div>
        </div>
      </Portal>
    )
  )
}

export default CallbackForm
